<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>{{$t('documentcenter.documentationcenter')}}</h1>
            <mdb-row>
              <mdb-col md="8">
                <mdb-card>
                  <mdb-card-header color="grey darken-3">{{$t('documentcenter.alldocuments')}}</mdb-card-header>
                  <mdb-card-body>
                    <mdb-spinner v-if="isloading" size="lg" />
                    <mdb-alert color="info" v-if="!isloading && !documents.length">{{$t('documentcenter.nodocumentavailable')}}</mdb-alert>
                    <mdb-tbl sm>
                      <mdb-tbl-body>
                        <tr v-for="document in documents" v-bind:key="document.id" class="showhover">
                          <td>
                            <a :href="`/api/document/download?file=${document.filename}`" target="_blank" v-if="$store.state.accessmode !== 'FULLACCESS'">{{document.name}}</a>
                            <div v-else>{{document.name}}</div>
                          </td>
                          <td>
                            <mdb-badge pill color="indigo" v-for="(label,index) in document.langlabels" v-bind:key="index" class="mr-1">{{label}}</mdb-badge>
                          </td>
                          <td v-if="$store.state.accessmode === 'FULLACCESS'" class="text-right">
                            <mdb-btn-group size="sm">
                              <mdb-btn color="grey lighten-2" icon="eye" v-on:click="viewfiledetails(document)" />
                              <mdb-btn color="red lighten-2" icon="trash-alt" v-on:click="deletefile(document)" />
                            </mdb-btn-group>
                          </td>
                        </tr>
                      </mdb-tbl-body>
                    </mdb-tbl>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
              <mdb-col md="4" v-if="$store.state.accessmode === 'FULLACCESS'">
                <mdb-card>
                  <mdb-card-header color="blue darken-3">{{$t('documentcenter.adddocument')}}</mdb-card-header>
                  <mdb-card-body>
                    <div>
                      <mdb-alert color="danger" v-if="uploaderror">{{uploaderror}}</mdb-alert>
                      <mdb-alert color="success" v-if="uploadsuccess">{{uploadsuccess}}</mdb-alert>
                      <br />
                      <mdb-file-input v-if="resetfileinpttweak" :btnTitle="$t('documentcenter.file')" :textFieldTitle="$t('documentcenter.choosepdffile')" @getValue="getFileInputValue" :sm="true" btnColor="light-blue" accept="application/pdf,application/vnd.ms-excel" />
                      <mdb-switch :onLabel="$t('documentcenter.fileavailableinfrench')" offLabel="" v-model="newfile.frenchavailable" />
                      <mdb-input :label="$t('documentcenter.frenchname')" v-if="newfile.frenchavailable" v-model="newfile.namefr" />
                      <mdb-switch :onLabel="$t('documentcenter.fileavailableinenglish')" offLabel="" v-model="newfile.englishavailable" />
                      <mdb-input :label="$t('documentcenter.englishname')" v-if="newfile.englishavailable" v-model="newfile.nameen" />
                      <mdb-col md="12" class="text-right">
                        <mdb-btn outline="primary" v-on:click="uploadAndSaveFile()" v-if="canshowuploadbtn()" size="md">{{$t('documentcenter.add')}}</mdb-btn>
                      </mdb-col>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </div>
      </main>
    </div>
    <mdb-modal :show="confirmdelete" @close="confirmdelete = false" size="md" class="text-center" danger>
      <mdb-modal-header center :close="false">
        <p class="heading">{{$t('users.are_you_sure')}}</p>
      </mdb-modal-header>
      <mdb-modal-body v-if="currentfile">
        {{$t('documentcenter.deletewarning')}} <br /><br /><strong>{{currentfile.name}}</strong><br />
        <mdb-icon icon="times" size="4x" class="animated rotateIn"/>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" v-on:click="confirmDeleteDocument()">{{$t('users.yes')}}</mdb-btn>
        <mdb-btn color="danger" @click="confirmdelete = false">{{$t('users.no')}}</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal :show="showfiledetails" @close="showfiledetails = false" size="md" class="" info>
      <mdb-modal-header center :close="false">
        <p class="heading">{{$t('documentcenter.fileinfo')}}</p>
      </mdb-modal-header>
      <mdb-modal-body v-if="currentfile">
        <mdb-tbl>
          <mdb-tbl-body>
            <tr>
              <td>{{$t('documentcenter.filename')}}</td>
              <td>{{currentfile.name}}</td>
            </tr>
            <tr>
              <td>{{$t('documentcenter.langs')}}</td>
              <td>
                <mdb-badge pill color="indigo" v-for="(label,index) in currentfile.langlabels" v-bind:key="index" class="mr-1">{{label}}</mdb-badge>
              </td>
            </tr>
            <tr>
              <td>{{$t('documentcenter.download')}}</td>
              <td>
                <a :href="`/api/document/download?file=${currentfile.filename}`" target="_blank">{{$t('documentcenter.download')}}</a>
              </td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="primary" @click="showfiledetails = false">{{$t('documentcenter.close')}}</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbBtnGroup,
  mdbContainer,
  mdbTbl,
  mdbTblBody,
  mdbAlert,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbIcon,
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbSpinner,
  mdbFileInput,
  mdbInput,
  mdbSwitch,
  mdbBadge,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'documentationCenter',
  data() {
    return {
      documents: [],
      isloading: true,
      uploadsuccess: null,
      uploaderror: null,
      newfile: {
        namefr: '',
        nameen: '',
        frenchavailable: true,
        englishavailable: false,
        gcloudfilename: '',
      },
      newfilecontent: null,
      currentsrtfilecontent: null,
      uploadlink: '',
      resetfileinpttweak: true,
      confirmdelete: false,
      currentfile: null,
      showfiledetails: false,
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbSpinner,
    mdbTbl,
    mdbTblBody,
    mdbAlert,
    mdbBtnGroup,
    mdbBtn,
    mdbFileInput,
    mdbInput,
    mdbSwitch,
    mdbBadge,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbIcon,
  },
  mounted() {
    this.loadAllDocuments();
  },
  methods: {
    loadAllDocuments() {
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/document/list').then((response) => {
        self.documents = response.data.documents;
        self.isloading = false;
      });
    },
    getFileInputValue(val) {
      this.newfilecontent = val;
    },
    canshowuploadbtn() {
      let canshow = true;
      if (this.newfile.frenchavailable && this.newfile.namefr === '') {
        canshow = false;
      }
      if (this.newfile.englishavailable && this.newfile.nameen === '') {
        canshow = false;
      }
      if (!this.newfile.frenchavailable && !this.newfile.englishavailable) {
        canshow = false;
      }
      if (!this.newfilecontent) {
        canshow = false;
      }
      return canshow;
    },
    confirmDeleteDocument() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/document/delete', { id: this.currentfile.id }).then(() => {
        self.confirmdelete = false;
        self.loadAllDocuments();
      });
    },
    deletefile(file) {
      this.currentfile = file;
      this.confirmdelete = true;
    },
    viewfiledetails(file) {
      this.currentfile = file;
      this.showfiledetails = true;
    },
    uploadAndSaveFile() {
      const originalfilename = this.newfilecontent[0].name;
      const self = this;
      const api = new Apicall();
      self.uploadsuccess = null;
      self.uploaderror = null;
      api.call('POST', 'api/document/get-upload-link', { filename: originalfilename, contenttype: self.newfilecontent[0].type }).then((response) => {
        if (response.status === 'OK') {
          self.uploadlink = response.data.filename.uploadlink;
          self.newfile.gcloudfilename = response.data.filename.filename;
          const uploadhndl = new XMLHttpRequest();
          uploadhndl.open('PUT', self.uploadlink, true);
          uploadhndl.setRequestHeader('Content-Type', self.newfilecontent[0].type);
          uploadhndl.onload = () => {
            if (uploadhndl.status === 200) {
              api.call('POST', 'api/document/add', self.newfile).then((addresponse) => {
                if (addresponse.status === 'OK') {
                  self.uploadsuccess = self.$t('documentcenter.filesavesuccess');
                  self.newfile.frenchavailable = true;
                  self.newfile.namefr = '';
                  self.newfile.englishavailable = false;
                  self.newfile.nameen = '';
                } else {
                  self.uploaderror = self.$t('documentcenter.uploaderror');
                }
                self.resetfileinpttweak = true;
                self.loadAllDocuments();
              });
            }
          };
          uploadhndl.onerror = () => {
            self.uploaderror = self.$t('documentcenter.uploaderror');
          };
          uploadhndl.send(self.newfilecontent[0]);
          self.resetfileinpttweak = false;
        } else {
          self.uploaderror = response.msg;
        }
      });
    },
  },
};
</script>

<style scoped>
tbody tr.showhover:hover{
  background-color: #f5f5f5;
}
tbody tr.showhover{
  cursor: pointer;
}
</style>
